// Import all of Bootstrap's JS
import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;

import Checkout from "~/js/checkout/checkout.js";
import Menu from "~/js/menu/menu.js";
import Header from "~/js/header/header.js";

document.addEventListener("DOMContentLoaded", function () {
  Menu.initialize();
  Checkout.initialize();
  Header.initialize();
});
