const Checkout = {
    ids: {
        paymentAddressSelector: 'payment-methods',
        paymentBillSelector: 'payment-method-2',
        paymentAddressContainer: 'payment-address'
    },

    /**
     * Initialize
     */
    initialize: () => {
        const paymentMethods = Array.from(document.querySelectorAll(`#${Checkout.ids.paymentAddressSelector} input`))
        const paymentBill = document.querySelector(`#${Checkout.ids.paymentBillSelector}`)
        const paymentAddress = document.querySelector(`#${Checkout.ids.paymentAddressContainer}`)

        if (paymentMethods.length && paymentAddress) {
            paymentMethods.forEach((item, index) =>  {
                item.addEventListener('click', () => {
                    if (paymentBill.checked) {
                        paymentAddress.classList.add('d-block')
                    } else {
                        paymentAddress.classList.remove('d-block')
                    }
                });
            });
        }
    }
}

export default Checkout;