const Header = {
    initialize: () => {
		if (window.innerWidth < 991) {
			$('body').addClass('scrolled');
			$('.border-warning').addClass('sticky');
		} else {
			document.querySelectorAll('.mm-btn--prev').forEach((prev) => {
				prev.addEventListener('click', removeHidden, false)
			})

			function removeHidden() {
				document.querySelectorAll('.mm-panel--parent').forEach((element) => {
					element.removeAttribute('inert');
				});
			}
		}

		window.onscroll = function() {stickyNav()};
		function stickyNav() {
			if (document.documentElement.scrollTop > 60) {
				$('body').addClass('scrolled');
				$('.border-warning').addClass('sticky');
				$('.header-container').addClass('small');
				$('.nav-accessible').hide();
			} else {
				$('body').removeClass('scrolled');
				$('.border-warning').removeClass('sticky');
				$('.header-container').removeClass('small');
				$('.nav-accessible').show();
			}
		}
	}
}

export default Header;
