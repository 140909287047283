const Menu = {
    initialize: () => {
        const menu = new Mmenu('#menu', {
            offCanvas: {
                position: 'top',
            },
            theme: 'light',
            navbars: [{
                position: 'bottom',
                content: [
                    '<a role="button" href="#" class="link link-icon btn-link link-dark mb-3"><i role="img" aria-hidden="true" class="bws-icons icon-simple-language"></i><span class="link-icon-text ps-2">Leichte Sprache</span></a>',
                    '<a role="button" href="#" class="link link-icon btn-link link-dark mb-3"><i role="img" aria-hidden="true" class="bws-icons icon-gesture"></i><span class="link-icon-text ps-2">Gebärdensprache</span></a>'
                ]
            }]
        }, {
            language: 'de',
            screenReader: {
                closeMenu: 'Menü schließen',
                openMenu: 'Menü öffnen',
                closeSubmenu: 'Untermenü schließen',
                openSubmenu: 'Untermenü öffnen',
                toggleSubmenu: 'Menü ein/ausblenden'
            }
        });

        document.querySelector('.mm-panels').classList.add('container');

        const menuItems = document.querySelectorAll('.mm-listitem');
        [].forEach.call(menuItems, (el) => {
            el.addEventListener('click', itemClick, false);
        });

        function itemClick() {
            [].forEach.call(menuItems, (el) => {
                el.classList.remove('active');
            });
        }

        // document.querySelector('.mm-navbar .mm-navbar__title span').textContent = "Menü";
        //the libary creates the submenu link with the aria label Open submenu to have a more described aria-label we are overwriting these items
        const submenuItems = document.querySelectorAll(
            '[aria-label="Untermenü öffnen"]'
        );
        for (const item of submenuItems) {
            item.setAttribute('aria-label', item.text + ' hat Untermenü');
        }

        const mmBlocker = document.querySelectorAll(
            '[aria-label="Close menu"]'
        );
        for (const block of mmBlocker) {
            block.setAttribute('aria-label', 'Menü schließen');
        }

        //Focus erster menüpunkt
        let prevBtns = document.querySelectorAll('.mm-btn--prev');
        let nextBtns = document.querySelectorAll('.mm-btn--next');
        
        prevBtns.forEach(item => {
            item.addEventListener('click', event => {
                let mmPanelFirst = document.querySelector('.mm-panel--opened');
                mmPanelFirst.focus();
                let firstElem = mmPanelFirst.querySelector('.mm-listview').children[0];
                firstElem.querySelector('a').focus();
            })
        })

        nextBtns.forEach(next => {
            next.addEventListener('click', event => {
                let targetPanel = event.target.hash;
                let targetString = document.querySelector(targetPanel.toString());
                setTimeout(function(){
                    targetString.querySelector('.mm-btn--prev').focus();
                }, 300);
            })
        })
    },
};

export default Menu;